const $ = require("jquery");

export async function initStreams() {
  try {
    let box = $('[data-section="streams"]');
    let resizeTimeout;
    $(window)
      .on("resize", function () {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(function () {
          if (
            box.height() > 256 &&
            $(document).outerHeight(true) > 900 &&
            $(document).outerWidth(true) > 1000
          ) {
            $(".stream_fullscreen").addClass(
              "stream_fullscreen_visible fadein"
            );
          } else {
            $(".stream_fullscreen")
              .removeClass("stream_fullscreen_visible")
              .addClass("fadeout");
          }
        }, 50);
      })
      .trigger("resize");
  } catch (e) {
    console.error(e);
  }
}
