<template>
  <div class="bar">
    <FunctionBtn
      v-for="(btn, index) in function_buttons"
      :key="index"
      :btn="btn"
    ></FunctionBtn>
    <LanguageSwitcher></LanguageSwitcher>
    <BurgerMenu></BurgerMenu>
  </div>
</template>

<script>
import FunctionBtn from "@/components/Header/FunctionBtn";
import LanguageSwitcher from "@/components/Header/LanguageSwitcher";
import BurgerMenu from "@/components/Header/BurgerMenu";

export default {
  name: "RightBar",
  components: { BurgerMenu, LanguageSwitcher, FunctionBtn },
  data() {
    return {};
  },
  computed: {
    function_buttons() {
      if (this.$store.getters.get_logged_in) {
        return [
          {
            imgSrc: require("@/assets/images/btns/ico-acc.png"),
            alt: "account",
            route: "/account",
            routeName: "account",
          },
        ];
      } else {
        return [
          {
            imgSrc: require("@/assets/images/btns/ico-acc.png"),
            alt: "login",
            route: "/login",
            routeName: "login",
          },
        ];
      }
    },
  },
};
</script>

<style scoped></style>
