export default {
  /**
   * ACTIONS
   */
  actions: {},
  /**
   * MUTATIONS
   */
  mutations: {
    UPDATE_COINS(state, coins) {
      state.coins = coins;
    },
    UPDATE_PRICE(state, price) {
      state.price = price;
    },
    UPDATE_CHARACTER(state, character) {
      state.character = character;
    },
  },
  /**
   * STATE
   */
  state: {
    coins: 10,
    price: 0.75,
    character: null,
  },
  /**
   * GETTERS
   */
  getters: {
    get_coins(state) {
      return state.coins;
    },
    get_price(state) {
      return state.price;
    },
    get_char_name(state) {
      return state.character;
    },
  },
};
