<template>
  <div class="section" data-section="servers">
    <div class="container">
      <div class="servers">
        <!--        <PlayButton></PlayButton>-->
        <ServerItem
          v-for="(server, index) in get_servers"
          :key="index"
          :server="server"
        ></ServerItem>
      </div>
    </div>
  </div>
</template>

<script>
import ServerItem from "@/components/Body/Servers/ServerItem";
// import PlayButton from "@/components/Body/Servers/PlayButton";

export default {
  name: "Servers",
  data() {
    return {};
  },
  components: { ServerItem /*PlayButton*/ },
  computed: {
    get_servers() {
      return this.$store.getters.get_servers;
    },
  },
};
</script>

<style scoped></style>
