<template>
  <div class="mid__slider boxshadow">
    <div class="snews">
      <swiper
        ref="swiper"
        :slides-per-view="1"
        :space-between="10"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        @init="onInit"
        :autoplay="params.autoplay"
        :navigation="params.navigation"
        :pagination="params.pagination"
        preloadImages
        updateOnImagesReady
      >
        <swiper-slide
          class="snews__slide"
          v-for="(slide, index) in news"
          :key="index"
          :style="{ backgroundImage: `url('${slide.img}')` }"
        >
          <div class="snews__inner">
            <div class="snews__sub-title" v-html="slide.date"></div>
            <h2 class="snews__title" v-html="slide.title"></h2>
            <a :href="slide.url" target="_blank" class="snews__btn btn">
              <div class="btn__content">More</div>
            </a>
            <div class="snews__share share">
              <a
                v-for="(socialItem, index) in social_links"
                :key="index"
                :href="socialItem.href"
                class="share__item"
              >
                <img :src="socialItem.imgSrc" :alt="socialItem.alt" />
              </a>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div data-slider-pagination="snews" class="pag"></div>
      <div class="snews__controls">
        <div class="snews__arrows arrows">
          <div class="arrows__arrow arrows__arrow_prev" data-snews-prev>
            <div class="slider-prev"></div>
          </div>
          <div
            class="snews__counter"
            ref="slider_counter"
            data-slider-counter="snews"
          >
            {{ slide_counter }}
          </div>
          <div class="arrows__arrow arrows__arrow_next" data-snews-next>
            <div class="slider-next"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/swiper.min.css";

SwiperCore.use([Navigation, Autoplay, Pagination]);

export default {
  name: "NewsSlider",
  components: { Swiper, SwiperSlide },
  data() {
    return {
      slide_counter: "1/3",
      params: {
        init: true,
        navigation: {
          nextEl: "[data-snews-next]",
          prevEl: "[data-snews-prev]",
        },
        pagination: {
          el: '[data-slider-pagination="snews"]',
          bulletClass: "pag__bullet",
          clickable: true,
        },
        autoplay: {
          enabled: true,
          delay: 10000,
          disableOnInteraction: true,
        },
      },
    };
  },
  computed: {
    news() {
      return this.$store.getters.get_media_news;
    },
    swiper() {
      return this.$refs.swiper;
    },
    social_links() {
      return this.$store.getters.get_media_social_links;
    },
  },
  methods: {
    onSwiper(swiper) {
      swiper.$el.on("mouseenter", function () {
        swiper.autoplay.stop();
      });
      swiper.$el.on("mouseleave", function () {
        swiper.autoplay.start();
      });
    },
    onInit(swiper) {
      this.update_slide_counter(swiper);
    },
    onSlideChange(swiper) {
      this.update_slide_counter(swiper);
    },
    update_slide_counter(swiper) {
      this.$refs.slider_counter.innerHTML = `${swiper.activeIndex + 1}/${
        swiper.slides.length || 3
      }`;
    },
  },
};
</script>

<style scoped></style>
