<template v-if="!fetching_streams">
  <div class="section" data-section="streams" ref="streamSectionRef">
    <div class="container">
      <div class="streams">
        <StreamItem
          v-for="(stream, index) in streams"
          :key="index"
          :stream="stream"
        ></StreamItem>
      </div>
    </div>
  </div>
</template>

<script>
import StreamItem from "@/components/Body/Stream/StreamItem";
import { initStreams } from "@/assets/js/streams";
import { insertMedia } from "@/assets/js/insertMedia";

export default {
  name: "Streams",
  components: { StreamItem },
  computed: {
    fetching_streams() {
      return this.$store.getters.get_media_fetching_streams;
    },
    streams() {
      return this.$store.getters.get_media_streams;
    },
  },
  async mounted() {
    await initStreams();
    insertMedia({
      delay: 0,
      immediately: true,
      attr: "data-insertmedia",
    });

    this.$store.dispatch("update_body");
    this.$store.dispatch("init_modal_env");
  },
  data() {
    return {};
  },
};
</script>

<style scoped></style>
